import React from "react";

const DashboardIcon = <i className="bx bx-home side-menu__icon"></i>;

const ErrorIcon = <i className="bx bx-error side-menu__icon"></i>;

const NestedmenuIcon = <i className="bx bx-layer side-menu__icon"></i>;
const FormsIcon = <i className="bx bx-file  side-menu__icon"></i>
const AdvancedUiIcon = <i className="bx bx-party side-menu__icon"></i>
const Utilities = <i className="bx bx-medal side-menu__icon"></i>
const AppsIcon = <i className="bx bx-grid-alt side-menu__icon"></i>
const UiElementsIcon = <i className="bx bx-box side-menu__icon"></i>
const Uilogout  = <i className="bx bx-door-open  side-menu__icon"></i>

const badge = (
  <span className="badge !bg-warning/10 !text-warning !py-[0.25rem] !px-[0.45rem] !text-[0.75em] ms-2">
    12
  </span>
);

export const MenuItems = [
  {
    menutitle: "MAIN",
    Items: [
      {
        icon: DashboardIcon,
        badgetxt: badge,
        title: "Dashboard",
        type: "sub",
        active: false,
        children: [
          {
            path: "/dashboard",
            type: "link",
            active: false,
            selected: false,
            title: "Home",
          },
        ],
      },
    ],
  },
  {
    menutitle: "PAGES",
    Items: [
      {
        icon: FormsIcon,
        title: "Reports",
        type: "sub",
        active: false,
        selected: false,
        children: [
          { title: "Category Reports", type: "sub", menusub: true, active: false, selected: false, children: [
            {
              path: "/category-reports",
              type: "link",
              active: false,
              selected: false,
              title: "All Reports",
            },
            {
              path: "/superset-test/",
              type: "link",
              active: false,
              selected: false,
              title: "Sample Data Report",
            },
            {
              path: "/category-interactive-view/",
              type: "link",
              active: false,
              selected: false,
              title: "Category Drill Down",
            },
            
        ],
    },

    { title: "Supplier Reports", type: "sub", menusub: true, active: false, selected: false, children: [
      {
        path: "/supplier-reports",
        type: "link",
        active: false,
        selected: false,
        title: "All Reports",
      },
      {
        path: "/supplier-interactive-view/",
        type: "link",
        active: false,
        selected: false,
        title: "Supplier Drill Down",
      },
  ],
},


{ title: "Transaction Reports", type: "sub", menusub: true, active: false, selected: false, children: [
  {
    path: "/transaction-reports",
    type: "link",
    active: false,
    selected: false,
    title: "All Reports",
  },
  {
    path: "/transaction-test-report",
    type: "link",
    active: false,
    selected: false,
    title: "Test Reports",
  },
],
},
        ],
      },
      {
        icon: NestedmenuIcon,
        title: "Data",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/testdata",
            type: "link",
            active: false,
            selected: false,
            title: "Test Data",
          },
          {
            path: "/quickBooks",
            type: "link",
            active: false,
            selected: false,
            title: "QuickBooks",
          },
          {
            path: "/netSuite",
            type: "link",
            active: false,
            selected: false,
            title: "NetSuite ",
          },
        ],
      },
      {
        icon: UiElementsIcon,
        title: "Administration",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/usersettings",
            type: "link",
            active: false,
            selected: false,
            title: "User Settings",
          },
          {
            path: "/billing",
            type: "link",
            active: false,
            selected: false,
            title: "Billing ",
          },
          {
            path: "/organization",
            type: "link",
            active: false,
            selected: false,
            title: "Organization",
          },
        ],
      },
      {
        icon: Utilities,
        title: "Task",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/mytasks",
            type: "link",
            active: false,
            selected: false,
            title: "My Tasks",
          },
          {
            path: "/createdtasks",
            type: "link",
            active: false,
            selected: false,
            title: "Created Tasks ",
          },
        ],
      },
      {
        icon: AdvancedUiIcon,
        title: "Goals",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/mygoals",
            type: "link",
            active: false,
            selected: false,
            title: "My Goals",
          },
          {
            path: "/createdtasks",
            type: "link",
            active: false,
            selected: false,
            title: "Created Tasks ",
          },
        ],
      },

      {
        icon: AppsIcon,
        title: "Compliance",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/rules",
            type: "link",
            active: false,
            selected: false,
            title: "Rules",
          },
          {
            path: "/addnew",
            type: "link",
            active: false,
            selected: false,
            title: "Add New ",
          },
        ],
      },
      
      
    ],
    
  },
  {

    Items: [
      {
        icon: Uilogout,
        title: "Log Out",
        path: "/",
        type: "link",
        selected: false,
        active: false,
      },
    ],
  },
  
  
];
export default MenuItems;
