/**@type {import('next').NextConfig} */
const nextConfig = {
  reactStrictMode: true,
  trailingSlash: true, // Remove this line if you've already specified it above
  swcMinify: true,
  productionBrowserSourceMaps: true,
  // basePath: isProd ? "/ynex-js/preview" : undefined,
  basePath: '', // You can leave this line as it is
  // assetPrefix : isProd ? "https://nextjs.spruko.com/ynex-js/preview/" : undefined,
  assetPrefix: '/', // You can leave this line as it is
  images: {
    loader: "imgix",
    path: "/",
  },
  // eslint: {
  //   ignoreDuringBuilds: true,
  // },
  // output: 'export', // Remove this line if you've already specified it above
  distDir: 'custom_build',
  webpack: function (config, { dev, isServer }) {
    // Only modify the webpack configuration for client-side bundles
    if (!isServer && dev) {
      config.watchOptions = {
        poll: 1000,
        aggregateTimeout: 300,
      };
    }
    return config;
  },
};

module.exports = nextConfig;
