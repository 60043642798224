import React from 'react'
import Link from 'next/link';

export const TopHeader = () => {
  return (
<div className="py-[0.65rem] md:px-[0.65rem] px-2">
  {/* Collapse */}
  <div
    id="navbar-collapse-with-animation"
    className="hs-collapse hidden overflow-hidden transition-all duration-300 lg:block"
  >
    <div className="overflow-hidden overflow-y-auto max-h-[75vh] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
      <div className="lg:flex lg:items-center lg:gap-x-2 pt-2 lg:pt-0">
        {/* Dropdown Link */}
        <div className="hs-dropdown top-header lg:inline-block [--strategy:static] lg:[--strategy:absolute] [--adaptive:none] lg:[--trigger:hover]">
          {/* Link */}
          <button
            id="hs-pro-enlpd"
            type="button"
            className="hs-dropdown-toggle flex gap-x-3 lg:gap-x-1.5 py-2 px-3 w-full lg:w-auto items-center text-sm text-start text-white hover:text-stone-800 rounded-lg hover:bg-stone-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
          >
            <svg
              className="lg:hidden flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z" />
              <path d="m3 9 2.45-4.9A2 2 0 0 1 7.24 3h9.52a2 2 0 0 1 1.8 1.1L21 9" />
              <path d="M12 3v6" />
            </svg>
            Spend
            <svg
              className="ms-auto lg:ms-0 flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m6 9 6 6 6-6" />
            </svg>
          </button>
 
          <div
            className="hs-dropdown-menu top-header transition-[opacity,margin] duration-[0.1ms] lg:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 relative w-full lg:w-48 hidden z-10 top-full bg-white lg:rounded-lg lg:shadow-xl shadow-stone-200 pt-1 lg:pb-1 ps-7 lg:px-1 space-y-0.5 before:absolute before:-top-4 before:start-0 before:w-full before:h-5 lg:after:hidden after:absolute after:top-1 after:start-[18px] after:w-0.5 after:h-[calc(100%-0.25rem)] after:bg-gray-100 dark:bg-neutral-800 dark:shadow-neutral-900 dark:after:bg-neutral-700"
            aria-labelledby="hs-pro-enlpd"
          >
            <Link href="/supplier-reports">
            <div  className="group top-header py-2 px-3 flex items-center gap-x-3 text-sm text-stone-800 hover:bg-stone-100 focus:outline-none focus:bg-stone-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
            >
             All Reports
             </div>
            </Link>
            <Link href="/supplier-test-report">
            <div  className="group top-header py-2 px-3 flex items-center gap-x-3 text-sm text-stone-800 hover:bg-stone-100 focus:outline-none focus:bg-stone-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
            >
           Test Reports
           </div>
            </Link>


  
          </div>
          {/* End Dropdown Menu */}
        </div>
        {/* End Dropdown Link */}
        {/* Dropdown Link */}
        <div className="hs-dropdown top-header lg:inline-block [--strategy:static] lg:[--strategy:absolute] [--adaptive:none] lg:[--trigger:hover]">
          {/* Link */}
          <button
            id="hs-pro-enlpd"
            type="button"
            className="hs-dropdown-toggle flex gap-x-3 lg:gap-x-1.5 py-2 px-3 w-full lg:w-auto items-center text-sm text-start text-white hover:text-stone-800 rounded-lg hover:bg-stone-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
          >
            <svg
              className="lg:hidden flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z" />
              <path d="m3 9 2.45-4.9A2 2 0 0 1 7.24 3h9.52a2 2 0 0 1 1.8 1.1L21 9" />
              <path d="M12 3v6" />
            </svg>
            Category
            <svg
              className="ms-auto lg:ms-0 flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m6 9 6 6 6-6" />
            </svg>
          </button>
          {/* End Link */}
          {/* Dropdown Menu */}
          <div
            className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] lg:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 relative w-full lg:w-48 hidden z-10 top-full bg-white lg:rounded-lg lg:shadow-xl shadow-stone-200 pt-1 lg:pb-1 ps-7 lg:px-1 space-y-0.5 before:absolute before:-top-4 before:start-0 before:w-full before:h-5 lg:after:hidden after:absolute after:top-1 after:start-[18px] after:w-0.5 after:h-[calc(100%-0.25rem)] after:bg-gray-100 dark:bg-neutral-800 dark:shadow-neutral-900 dark:after:bg-neutral-700"
            aria-labelledby="hs-pro-enlpd"
          >
            <Link href="/transaction-reports">
              <div className="group top-header py-2 px-3 flex items-center gap-x-3 text-sm text-stone-800 hover:bg-stone-100 focus:outline-none focus:bg-stone-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
            >
             All Reports
             </div>
            </Link>
            <Link href="/transaction-test-report">
              <div className="group top-header py-2 px-3 flex items-center gap-x-3 text-sm text-stone-800 hover:bg-stone-100 focus:outline-none focus:bg-stone-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
            >
           Test Reports
           </div>
            </Link>


  
          </div>
          {/* End Dropdown Menu */}
        </div>
        {/* End Dropdown Link */}
        {/* Dropdown Link */}
        <div className="hs-dropdown top-header lg:inline-block [--strategy:static] lg:[--strategy:absolute] [--adaptive:none] lg:[--trigger:hover]">
          {/* Link */}
          <button
            id="hs-pro-enlpd"
            type="button"
            className="hs-dropdown-toggle flex gap-x-3 lg:gap-x-1.5 py-2 px-3 w-full lg:w-auto items-center text-sm text-start text-white hover:text-stone-800 rounded-lg hover:bg-stone-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
          >
            <svg
              className="lg:hidden flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z" />
              <path d="m3 9 2.45-4.9A2 2 0 0 1 7.24 3h9.52a2 2 0 0 1 1.8 1.1L21 9" />
              <path d="M12 3v6" />
            </svg>
            Supplier
            <svg
              className="ms-auto lg:ms-0 flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m6 9 6 6 6-6" />
            </svg>
          </button>
 
          <div
            className="hs-dropdown-menu top-header transition-[opacity,margin] duration-[0.1ms] lg:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 relative w-full lg:w-48 hidden z-10 top-full bg-white lg:rounded-lg lg:shadow-xl shadow-stone-200 pt-1 lg:pb-1 ps-7 lg:px-1 space-y-0.5 before:absolute before:-top-4 before:start-0 before:w-full before:h-5 lg:after:hidden after:absolute after:top-1 after:start-[18px] after:w-0.5 after:h-[calc(100%-0.25rem)] after:bg-gray-100 dark:bg-neutral-800 dark:shadow-neutral-900 dark:after:bg-neutral-700"
            aria-labelledby="hs-pro-enlpd"
          >
            <Link href="/supplier-reports">
            <div  className="group top-header py-2 px-3 flex items-center gap-x-3 text-sm text-stone-800 hover:bg-stone-100 focus:outline-none focus:bg-stone-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
            >
             All Reports
             </div>
            </Link>
            <Link href="/supplier-interactive-view/">
            <div  className="group top-header py-2 px-3 flex items-center gap-x-3 text-sm text-stone-800 hover:bg-stone-100 focus:outline-none focus:bg-stone-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
            >
           Supplier Drill Down
           </div>
            </Link>


  
          </div>
          {/* End Dropdown Menu */}
        </div>
        {/* End Dropdown Link */}
        {/* Dropdown Link */}
        <div className="hs-dropdown top-header lg:inline-block [--strategy:static] lg:[--strategy:absolute] [--adaptive:none] lg:[--trigger:hover]">
          {/* Link */}
          <button
            id="hs-pro-enlpd"
            type="button"
            className="hs-dropdown-toggle flex gap-x-3 lg:gap-x-1.5 py-2 px-3 w-full lg:w-auto items-center text-sm text-start text-white hover:text-stone-800 rounded-lg hover:bg-stone-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-stone-100 dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
          >
            <svg
              className="lg:hidden flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z" />
              <path d="m3 9 2.45-4.9A2 2 0 0 1 7.24 3h9.52a2 2 0 0 1 1.8 1.1L21 9" />
              <path d="M12 3v6" />
            </svg>
            Transaction
            <svg
              className="ms-auto lg:ms-0 flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m6 9 6 6 6-6" />
            </svg>
          </button>
          {/* End Link */}
          {/* Dropdown Menu */}
          <div
            className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] lg:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 relative w-full lg:w-48 hidden z-10 top-full bg-white lg:rounded-lg lg:shadow-xl shadow-stone-200 pt-1 lg:pb-1 ps-7 lg:px-1 space-y-0.5 before:absolute before:-top-4 before:start-0 before:w-full before:h-5 lg:after:hidden after:absolute after:top-1 after:start-[18px] after:w-0.5 after:h-[calc(100%-0.25rem)] after:bg-gray-100 dark:bg-neutral-800 dark:shadow-neutral-900 dark:after:bg-neutral-700"
            aria-labelledby="hs-pro-enlpd"
          >
            <Link href="/transaction-reports">
              <div className="group top-header py-2 px-3 flex items-center gap-x-3 text-sm text-stone-800 hover:bg-stone-100 focus:outline-none focus:bg-stone-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
            >
             All Reports
             </div>
            </Link>
            <Link href="/transaction-test-report">
              <div className="group top-header py-2 px-3 flex items-center gap-x-3 text-sm text-stone-800 hover:bg-stone-100 focus:outline-none focus:bg-stone-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 "
            >
           Test Reports
           </div>
            </Link>


  
          </div>
          {/* End Dropdown Menu */}
        </div>
        {/* End Dropdown Link */}
      </div>
    </div>
  </div>
  {/* End Collapse */}
</div>

  )
}
